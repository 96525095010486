import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const CopyrightPage = () => (
  <Layout>
    <Seo title="Avtorskie prava" pathname={'avtorskiye-prava'} />
    <div className="article underline">
      <section id="copyright">
        <p>
          &#169; 2022 Billy Graham Evangelistic Association. Все права защищены.
          Авторские права на все материалы данного сайта принадлежат
          Евангелистской Ассоциации Билли Грэма, если не указано иначе. Все
          содержание, находящееся на этом сайте, как то: тексты, графика, аудио
          клипы - является исключительной собственностью Евангелистской
          Ассоциации Билли Грэма, защищено законодательством в области авторских
          прав и не может быть использовано без письменного разрешения
          Евангелистской Ассоциации Билли Грэма, расположенной по адресу: 1
          Billy Graham Parkway, Charlotte, NC 28201, USA.
        </p>
        <p>
          Ограничение на репродукцию тем не менее не запрещает личного
          использования материалов. Вы можете просматривать и распечатывать
          материал для личного использования. Пожалуйста, имейте в виду, что
          личное использование не включает в себя использование среди больших
          групп людей или продажу нашего материала.
        </p>
        <p>
          Вы можете ссылаться на этот веб сайт, если получено соответствующее
          разрешение от BGEA и ссылка соответствует всем требованиям ассоциации.
        </p>
        <p>
          Если у Вас есть вопросы, касающиеся авторских правах этого сайта,
          пожалуйста{' '}
          <a href="mailto:mirsbogom@billygraham.org">свяжитесь с нами</a>.
        </p>
        <ul>
          <li>
            <a href="/o-nas/">О Нас</a>
          </li>
          <li>
            <a
              href="https://billygraham.org/politika-konfidentsialnost/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Политика Конфиденциальности
            </a>
          </li>
          <li>
            <a href="/avtorskiye-prava/">Авторские Права</a>
          </li>
        </ul>
      </section>
    </div>
  </Layout>
);

export default CopyrightPage;
